<template>
    <div>
        <div class="card">
            <div class="bg-blue-light px-1 rounded pb-2">
                <TitleButton
                        class="mt-1"
                        btnTitle="Go Back"
                        title="Create Payment"
                        @onClickCloseButton="goToList"
                />

                <div class="row mt-1">

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Voucher Date</label>
                        <input
                                class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                                tabindex="1"
                                type="date"
                                placeholder=""
                                v-model="payment.date"
                        >
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label text-end">Voucher No</label>
                        <input type="hidden" class="form-control invoice-edit-input date-picker flatpickr-input" readonly="readonly">
                        <div
                            class="input-group input-group-merge invoice-edit-input-group has-validation"
                            :class="{'is-invalid':isEmptyVoucher}"
                        >
                            <div class="input-group-text">
                                <span :class="{'text-danger':isEmptyVoucher}">{{ payment.voucher_prefix }}-</span>
                            </div>
                            <input
                                type="text"
                                class="form-control invoice-edit-input"
                                aria-describedby="validationVoucherNameFeedback"
                                placeholder=""
                                v-model="payment.voucher_serial"
                                :class="{'is-invalid': isEmptyVoucher}"
                            >
                        </div>
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Paid from: </label>
                        <v-select
                                placeholder="Select Cash & Bank Account"
                                class="w-100"
                                v-model="credit.account_head_id"
                                :options="cashAndBack"
                                label="name"
                                :reduce="name => name.id"
                        />
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Payment Ref</label>
                        <input
                                type="text"
                                class="form-control invoice-edit-input"
                                placeholder="Payment Reference"
                                v-model="payment.payment_ref">
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Payment Ref Date </label>
                        <input
                                class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                                tabindex="1"
                                type="date"
                                placeholder=""
                                v-model="payment.payment_ref_date"
                        >
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Project</label>
                        <v-select
                                placeholder="Select Project"
                                v-model="credit.project_id"
                                :options="projects"
                                label="name"
                                :reduce="name => name.id"
                                @option:selected="onSelectProject(true)"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Donors</label>
                        <v-select
                                placeholder="Select Donor"
                                class="w-100"
                                :options="donors"
                                label="name"
                                :reduce="name => name.id"
                                v-model="credit.donor_id"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Cost Centre</label>
                        <v-select
                                placeholder="Select Cost Centre"
                                v-model="credit.cost_centre_id"
                                :options="costCentres"
                                label="name"
                                :reduce="name => name.id"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Party</label>
                        <v-select
                                placeholder="Select Party"
                                v-model="credit.contact_profile_id"
                                :options="contactProfiles"
                                label="name"
                                :reduce="name => name.id"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Business</label>
                        <v-select
                                placeholder="Select Business"
                                class="w-100"
                                :options="businesses"
                                label="name"
                                :reduce="name => name.id"
                                v-model="payment.business_id"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">PR / PO Reference</label>
                        <div class="input-group mb-3">
                            <input v-model="payment.txn_no" type="text" class="form-control" placeholder="PR / PO Referance">
                            <button @click="openPrPoModal()" class="btn btn-outline-secondary" type="button" id="button-addon2">Select</button>
                        </div>
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Tag Register</label>
                        <v-select
                            placeholder="Select Tag Register"
                            class="w-100"
                            :options="tagRegisters"
                            label="name"
                            :reduce="name => name.id"
                            v-model="credit.tag_register_id"
                        />
                    </div>
                </div>
            </div>
            <div class="px-2 mt-2">
                <AddFormElement
                        class="mb-2"
                        :accountHeads="accountHeads"
                        :ngoabHeads="ngoabHeads"
                        :budgets="budgets"
                        :programs="programs"
                        :projects="projects"
                        :costCentres="costCentres"
                        :contactProfiles="contactProfiles"
                        :businesses="businesses"
                        :paymentDate="payment.date"
                        v-for="(item, index) in payment.general_ledgers"
                        :key="index"
                        :index="index"
                        :item="item"
                        @onClose="onClose"
                        @isLoading="isDataLoading = true"
                        @loaded="isDataLoading = false"
                />

                <div class="row me-1 mt-3">
                    <div class="col-12 col-sm-4 col-lg-8 mb-1 mb-sm-0">
                        <AddButton title="Add line" @onClickAdd="onClickAdd"/>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-4"><input readonly type="number" class="form-control text-end" :value="totalDebit"></div>
                </div>

                <div class="row mt-3">
                    <div class="col-12">
                        <div class="mb-2">
                            <label for="attachment" class="form-label fw-bold">Attachment</label>
                            <BlobFileViewer :attachments="payment.attachments" />
                            <FileUpload
                                    :is-drag-active="true"
                                    button-text="Upload Documents"
                                    v-model="payment.attachments"
                            >
                            </FileUpload>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-12">
                        <div class="mb-2">
                            <label for="note" class="form-label fw-bold">Memo</label>
                            <textarea v-model="payment.note" placeholder="Memo" class="form-control" rows="2" id="note"></textarea>
                        </div>
                    </div>
                </div>

                <div class="my-2 text-center">
                    <hr class="mb-1">
                    <button
                            :disabled="paymentLoading || isEmptyVoucher"
                            @click="savePayment(true)"
                            class="btn btn-primary me-1"
                    >
                        <div v-if="paymentLoading" class="spinner-border spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Save
                    </button>
                    <button
                            :disabled="saveNewLoader || isEmptyVoucher"
                            @click="savePayment(false)"
                            class="btn btn-primary me-1"
                    >
                        <div v-if="saveNewLoader" class="spinner-border spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Save & New
                    </button>
                    <button
                            :disabled="saveNewLoader || isEmptyVoucher"
                            @click="savePayment(true, true)"
                            class="btn btn-primary me-1"
                    >
                        <div v-if="saveNewLoader" class="spinner-border spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Save & Print
                    </button>
                    <button
                            @click="goToList"
                            class="btn btn-outline-secondary cancel-btn"
                    >
                        Cancel
                    </button>
                </div>
            </div>
            <!-- PR PO Modal -->
            <PrPoModal
                :lists="prPoLists"
                ref="prPoModal"
                @selectedPrPo="selectedPrPo"
            />
            <Loader v-if="isDataLoading"/>
        </div>
        <VoucherValidationModal
            ref="voucherValidationModal"
            :voucherQuery="voucherQuery"
            @on-press-ok="setVoucherNumberAndDate(true)"
        />
    </div>
</template>

<script>
import handlePayment from '@/services/modules/payment'
import {inject} from 'vue'
import {generateVoucherNumber, generateQuery} from "@/services/utils/voucherNumberGenerator";
import TitleButton from '@/components/atom/TitleButton'
import AddFormElement from '@/components/molecule/ngo/payment/AddFormElement'
import PrPoModal from '@/components/molecule/ngo/payment/PrPoModal'
import AddButton from '@/components/atom/AddButton'
import handleContact from '@/services/modules/contact'
import handleNGOPrograms from "@/services/modules/ngo/program";
import handleCostCentres from "@/services/modules/ngo/costCentre";
import handleActivities from "@/services/modules/ngo/activity";
import FileUpload from "@/components/atom/FileUpload";
import BlobFileViewer from "@/components/atom/BlobFileViewer";
import handleProjects from "@/services/modules/procurement/project";
import Loader from '@/components/atom/LoaderComponent'
import handleFD6Projects from "@/services/modules/procurement/fd6";
import handleCBusinesses from '@/services/modules/businesses'
import handleTagRegister from '@/services/modules/scm/tag-register'
import VoucherValidationModal from "@/components/molecule/company/voucher/VoucherValidationModal.vue";
import figureFormatter from "@/services/utils/figureFormatter";
import handleJournal from '@/services/modules/journal'
import pdfPrinterHelper from '@/services/utils/pdfPrinterHelper';

export default {
    name: 'PaymentCreate',
    components: {
        VoucherValidationModal,
        BlobFileViewer,
        FileUpload,
        TitleButton,
        AddFormElement,
        PrPoModal,
        AddButton,
        Loader
    },
    data: () => ({
        cashAndBack: [],
        accountHeads: [],
        contactProfiles : [],
        projects : [],
        programs : [],
        costCentres : [],
        businesses : [],
        tagRegisters : [],
        prPoLists : [],
        activities : [],
        budgets : [],
        budgetHeads : [],
        ngoabHeads : [],
        donors : [],
        saveNewLoader: false,
        isDataLoading: false,
        isEmptyVoucher: false,
        credit: {
            account_head_id: null,
            project_id: null,
            donor_id: null,
            cost_centre_id: null,
            contact_profile_id: null,
            tag_register_id: null,
        },
        payment: {
            mop_account_head_id:'',
            company_id:'',
            contact_profile_id: null,
            project_id : null,
            program_id : null,
            donor_id: null,
            cost_centre_id: null,
            business_id: null,
            txn_no: null,
            date: '',
            note: '',
            mop_references: 'mop',
            voucher_no : '',
            voucher_prefix : '',
            voucher_serial : '',
            payment_ref : '',
            payment_ref_date : '',
            attachments: [],
            voucher_type: 'payment_voucher',
            general_ledgers: [
                {
                    account_head_id: null,
                    ngoab_budget_id: null,
                    budget_head_id: null,
                    program_id: null,
                    project_id: null,
                    activity_id: null,
                    cost_centre_id: null,
                    tag_register_id: null,
                    business_id: null,
                    debit: null,
                    credit: null,
                    note: '',
                    tax_rate: null,
                    taxable_amount: null
                },
                {
                  account_head_id: null,
                  ngoab_budget_id: null,
                  budget_head_id: null,
                  program_id: null,
                  project_id: null,
                  activity_id: null,
                  cost_centre_id: null,
                  tag_register_id: null,
                  business_id: null,
                  debit: null,
                  credit: null,
                  note: '',
                  tax_rate: null,
                  taxable_amount: null
                }
            ]
        },
        paymentRes: {},
    }),
    computed: {
        isLoading () {
            return this.paymentLoading || this.saveNewLoader
        },
        start () {
            return this.$route.query.start
        },
        end () {
            return this.$route.query.end
        },
        general () {
            return this.payment.general_ledgers
        },
        totalDebit () {
            let total = 0;
            this.general.map(i => {
                if(i.debit) total += i.debit
            })
            return total
        },
        voucherQuery () {
            return generateQuery(this.$route.params.companyId, 'payment_voucher', 'general_payment');
        }
    },

    watch: {
        'payment.voucher_prefix':function () {
            this.concatVoucher()
        },

        'payment.voucher_serial':function () {
            this.concatVoucher()
        }
    },

    methods: {
        selectedPrPo(pr_po) {
          this.payment.txn_no = pr_po;
        },
        openPrPoModal() {
          this.$refs.prPoModal.toggleModal()
        },
        concatVoucher(){
            this.isEmptyVoucher = false;
            let voucherSerial = this.decimalFormat(this.payment.voucher_serial.toString().replace(/[^0-9\.]+/g, ''))
            if(voucherSerial === '') {
                this.isEmptyVoucher = true;
            }
            this.payment.voucher_serial = voucherSerial;
            this.payment.voucher_no = `${this.payment.voucher_prefix}-${voucherSerial}`;
        },

        goToPrint(id) {
            const companyQuery = `?company_id=${this.$route.params.companyId}`;
            this.fetchSinglePayment(id, companyQuery).then( (res) => {
            if(res){
                this.paymentRes = res.data;
            }}).then (() => {
                this.generatePdf(this.$route.params.companyId, this.paymentRes, 'payment')
            }).catch((err) => {
                console.log('Something went wrong');
            })
        },
        formatDate(date) {
            var formattedDate = date.toISOString().split('T')[0];
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var amOrPm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12 || 12;
            var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;
            return this.formatToDdMmYy(formattedDate) + ' ' + formattedTime
        },

        formatToDdMmYy(date) {
            var dat = date.split('-');
            return `${dat[2]}-${dat[1]}-${dat[0]}`;
        },
        setVoucherNumberAndDate(withoutDate = false) {
            new Promise(async (resolve, reject) => {
                try {
                    if(! withoutDate) {
                        this.payment.date = new Date().toISOString().split('T')[0]
                    }
                    let query = this.voucherQuery;
                    let voucher = await generateVoucherNumber(query);
                    this.payment.voucher_prefix = voucher.prefix;
                    this.payment.voucher_serial = voucher.serial;
                    resolve();
                } catch (err) {
                    reject(err);
                }
            })

        },
        goToList() {
            this.$router.push({name: 'ngo-payment-voucher',
                params: {
                    companyId: this.$route.params.companyId,
                    moduleId: this.$route.params.moduleId,
                    menuId: this.$route.params.menuId,
                    pageId: this.$route.params.pageId
                },
                query: {
                    start: this.start,
                    end: this.end
                }

            })
        },
        onClose(index) {
            this.payment.general_ledgers.splice(index, 1)
        },
        onClickAdd() {
            this.payment.general_ledgers.push({
                account_head_id: null,
                debit: null,
                credit: null,
                note: '',
                tax_rate: null,
                taxable_amount: null
            })
        },
        async resetForm() {
            this.payment = {
                mop_account_head_id:'',
                company_id:'',
                date: '',
                note: '',
                mop_references: 'mop',
                voucher_type: 'payment_voucher',
                voucher_prefix : '',
                voucher_serial : '',
                general_ledgers: [
                    {
                        account_head_id: null,
                        debit: null,
                        credit: null,
                        note: '',
                        tax_rate: null,
                        taxable_amount: null
                    },
                    {
                        account_head_id: null,
                        debit: null,
                        credit: null,
                        note: '',
                        tax_rate: null,
                        taxable_amount: null
                    }
                ]
            }
            await this.setVoucherNumberAndDate()
        },
        getFormData () {
            let formData = new FormData();
            if(!this.payment.contact_profile_id) this.payment.contact_profile_id = ''
            if(!this.payment.txn_no) this.payment.txn_no = ''
            Object.keys(this.payment).forEach(i => {
                if(i !== 'general_ledgers') {
                    formData.append(i, this.payment[i])
                }
            })
            let generalLedgerData = this.general.slice()
            let tag_register_id = this.credit.tag_register_id;
            let mop_account_head_id = this.credit.account_head_id;
            generalLedgerData.forEach(function(generalLedger) {
                generalLedger.tag_register_id = tag_register_id;
                generalLedger.mop_account_head_id = mop_account_head_id;
            });
            generalLedgerData.push({
                account_head_id: this.credit.account_head_id,
                project_id: this.credit.project_id,
                donor_id: this.credit.donor_id,
                cost_centre_id: this.credit.cost_centre_id,
                contact_profile_id: this.credit.contact_profile_id,
                business_id: this.payment.business_id,
                tag_register_id: tag_register_id,
                debit: null,
                credit: this.totalDebit,
                note: this.payment.note,
                tax_rate: null,
                taxable_amount: null
            })
            formData.append('general_ledgers', JSON.stringify(generalLedgerData))

            if(this.payment.attachments) {
                for(let i=0; i<this.payment.attachments.length; i++) {
                    let key = `attachments[${i}]`;
                    let value = this.payment.attachments[i];
                    formData.append(key, value);
                }
            }

            return formData;
        },

        async savePayment (redirect = false, print = false) {//createPayment
            this.payment.company_id = this.$route.params.companyId;
            let data = this.getFormData();

            if(redirect) {
                this.paymentLoading = true
            } else {
                this.saveNewLoader = true
            }
            try {
                let res = await this.createPayment(data)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.showSuccess(res.message)
                    await this.resetForm()
                    if(redirect && print) this.goToPrint(res.data.id)
                    if(redirect){
                        this.$router.push({
                            name: 'ngo-payment-voucher',
                            params: {
                              companyId: this.$route.params.companyId,
                              moduleId: this.$route.params.moduleId,
                              menuId: this.$route.params.menuId,
                              pageId: this.$route.params.pageId,
                            },
                            query: {
                                start: this.start,
                                end: this.end}
                        })                          
                    }
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                    return ;
                }

                if(err.response && err.response.status !== 406) {
                    this.showError(err.response?.data.message)
                }
                if(err.response.data.data && err.response.data.data.show_modal) {
                    this.$refs.voucherValidationModal.openModal(err.response?.data.message)
                }
            } finally {
                this.paymentLoading = false
                this.saveNewLoader = false
            }
        },

        onSelectProject(resetDonor = false) {
            this.donors = [];
            if(resetDonor) this.credit.donor_id = null;
            for (let project of this.projects) {
                if(project.id === this.credit.project_id && project.party.hasOwnProperty('id')){
                    this.donors.push(project.party);
                    break;
                }
            }
        }
    },
    setup() {
        const showError =  inject('showError');
        const showSuccess =  inject('showSuccess');
        const {decimalFormat} = figureFormatter();

        const {
            createPayment,
            fetchCashAndBank,
            paymentLoading,
            fetchSinglePayment
        } = handlePayment()

        const {
            fetchAccountHeads
        } = handleJournal()

        const {
          fetchContactProfiles,
        } = handleContact()

        const {
          fetchProgramList,
        } = handleNGOPrograms()

        const {
          fetchCostCentreList,
        } = handleCostCentres()


        const {
          fetchActivityList,
        } = handleActivities()

        const {
            fetchProjects,
        } = handleProjects()

        const {
            fetchFD6AccountsAll
        } = handleFD6Projects()

        const {fetchBusinessList} = handleCBusinesses()
        const {fetchTagRegisterList, fetchPrPoList} = handleTagRegister()
        const { generatePdf } = pdfPrinterHelper();

        return {
            fetchAccountHeads,
            createPayment,
            fetchCashAndBank,
            fetchContactProfiles,
            paymentLoading,
            showError,
            showSuccess,
            fetchProgramList,
            fetchCostCentreList,
            fetchActivityList,
            fetchProjects,
            fetchFD6AccountsAll,
            fetchBusinessList,
            fetchTagRegisterList,
            fetchPrPoList,
            decimalFormat,
            fetchSinglePayment,
            generatePdf
        }
    },

    async mounted() {
        this.isDataLoading = true;
        let voucher    = null;
        let companyId = this.$route.params.companyId
        let contactId = '';
        let q = '';
        let companyQuery = '?company_id=' + companyId;
        let query = companyQuery + '&q=' + q + '&contact_id' + contactId;
        let projectQuery = companyQuery + '&with_donors=1';
        Promise.all([

            this.fetchAccountHeads(companyId, 'yes').then(res => {
                if(res.data){
                    this.accountHeads = res.data
                }
            }),
            this.fetchFD6AccountsAll(companyQuery).then(res => {
                if(res.data){
                    this.ngoabHeads = res.data
                }
            }),
            this.fetchProgramList(companyQuery).then(res => {
                if(res.data){
                    this.programs = res.data;
                }
            }),
            this.fetchCostCentreList(companyQuery).then(res => {
                if(res.data) {
                    this.costCentres = res.data
                }
            }),
            this.fetchActivityList(companyQuery).then(res => {
                if(res.data){
                    this.activities = res.data
                }
            }),
            this.fetchProjects(projectQuery).then(res => {
                if(res.data) {
                    this.projects = res.data
                }
            }),
            this.fetchCashAndBank(companyId).then(res => {
                if(res.data){
                    this.cashAndBack = res.data
                }
            }),
            this.fetchContactProfiles(query).then(res => {
                if(res.data) {
                    this.contactProfiles = res.data;
                }
            }),
            this.fetchBusinessList(companyQuery).then(res => {
                if(res.data) {
                    this.businesses = res.data;
                }
            }),
            this.fetchTagRegisterList(companyQuery).then(res => {
                if(res.data) {
                    this.tagRegisters = res.data;
                }
            }),
            this.fetchPrPoList(companyQuery).then(res => {
                if(res.data) {
                    this.prPoLists = res.data;
                }
            }),
            this.setVoucherNumberAndDate(),
        ]).then(() => {
            this.isDataLoading = false;
        }).catch(err => {
            this.isDataLoading = false;
            this.showError(err.message)
        }).finally(() => {
            this.isDataLoading = false;
        })

    }
}
</script>

<style scoped>
    .cancel-btn{
        background-color: #F0F2F5; color: #7D7D7D;
    }
    .cancel-btn:hover{
        background-color: #e9edf5;
    }
    hr{
        margin: 0 -3%;
        width: 106%;
    }
</style>
